<template>
  <div class="page_bg">
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name">{{ isMobile ? "更换号码":"个人信息" }}</div>
        <div class="top_icon" />
      </div>
      <!-- 用户信息 -->
      <div v-if="!isMobile" class="user_info_del">
        <van-form @submit="onSubmit">
          <van-field v-model="info.name" label="姓     名" placeholder="兽医首选用户" />
          <van-field v-model="info.industryName" readonly label="行业分类" placeholder="医院-宠医" />
          <van-field v-model="info.mobile" readonly label="手机号" placeholder="手机号">
            <div slot="extra" class="flex-r-c-c" style="color: #0767AB;font-size: 0.27rem;" @click="changeMobile">
              <van-icon name="replay" /><span>更换号码</span>
            </div>
          </van-field>
          <van-field v-model="info.companyName" label="公司名称" placeholder="公司名称" />
          <van-field v-model="info.companyPosition" label="职      位" placeholder="职      位" />
          <van-field v-model="info.districtName" readonly clickable label="所属地区" placeholder="请选择所在地区"
                     right-icon="arrow" @click="showPicker = true"
          />
          <div class="sub_btn">
            <van-button round block type="info" native-type="submit" color="#0767AB">保存信息</van-button>
          </div>
        </van-form>
      </div>
      <!-- 修改手机号 -->
      <div v-else class="user_info_del">
        <van-form @submit="mobileDialog = true">
          <van-field v-model="changeInfo.oldMobile" label="当前号码" readonly />
          <van-field v-model="changeInfo.newMobile" label="新手机号" placeholder="输入新的手机号码">
            <template slot="extra">
              <van-count-down v-if="isSend" :time="time" format="ss 秒重新发送" style="color: #0767AB;font-size: 0.27rem;"
                              @finish="finish"
              />
              <div v-else style="color: #0767AB;font-size: 0.27rem;" @click="sendMessage">获取验证码</div>
            </template>
          </van-field>
          <van-field v-model="changeInfo.code" type="digit" class="van-field" label="验证码" placeholder="请输入验证码"
                     maxlength="6"
          />
          <div class="sub_btn">
            <van-button round block type="info" color="#0767AB" native-type="submit">确认修改</van-button>
            <div class="mobile_tips" style="margin-top: 0.32rem;">仅可以更换到未注册手机号</div>
            <div class="mobile_tips">如需手机号互换，请联系业务经理</div>
          </div>
        </van-form>
      </div>
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
    <!-- 手机号 验证通过 -->
    <van-dialog v-model="mobileDialog" :show-confirm-button="false">

      <van-image width="3.47rem" height="2.93rem" class="mobileDialog_img"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/my/changeMobile.png"
      />
      <div class="mobileDialog_box flex-c-c-c">
        <div class="mobileDialog_title">验证通过 是否修改？</div>
        <div class="mobileDialog_tips">修改后，请使用新的手机号登录</div>
        <div class="mobileDialog_btn flex-r-sa-c">
          <div />
          <van-button round type="default" plain color="#0767AB" style="width: 2.93rem;" @click="mobileDialog = false">
            取消</van-button>
          <van-button round type="default" color="#0767AB" style="width: 2.93rem;" @click="onMobileSubmit">确认
          </van-button>
          <div />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import './index.scss'
import { districts } from '@/api/public'
import { user_userInfo, user_update, sms_send, modifyMobileCheck, modifyMobile } from '@/api/user'
export default {
  name: 'MyInfo',
  data() {
    return {
      info: {},
      isMobile: false,
      // 省市区
      showPicker: false,
      cascadervalue: [],
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      // 修改手机号
      changeInfo: {},
      isSend: false,
      time: 60 * 1000,
      // mobileDialog
      mobileDialog: false

    }
  },
  created() {
    this.getInfo()
    this.loadDistrict()
  },
  methods: {
    backPath() {
      if (this.isMobile) { this.isMobile = false } else { window.history.back() }
    },
    getInfo() {
      user_userInfo().then(res => {
        this.info = res.data
        this.info.districtName = res.data.provinceName + '-' + res.data.cityName + '-' + res.data.areaName
      })
    },
    onSubmit(values) {
      console.log('submit', values)
      user_update(this.info).then(res => {
        this.$toast({ message: '修改成功！', duration: 3 * 1000 })
        this.backPath()
      })
    },
    // 省市区
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.info.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.info.province = value.selectedOptions[0].value
      this.info.city = value.selectedOptions[1].value
      this.info.area = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() {
      this.showPicker = false
    },
    // 修改手机号
    changeMobile() {
      this.isMobile = true
      this.changeInfo = { oldMobile: this.info.mobile, newMobile: '', code: '' }
    },
    // 发送短信
    sendMessage() {
      modifyMobileCheck({newMobile: this.changeInfo.newMobile}).then(res => {
        if (this.changeInfo.newMobile.length === 11) {
          const parms = { mobile: this.changeInfo.newMobile, type: 'modifyMobile' }
          sms_send(parms).then(res => {
            this.$toast({ message: '已发送！', duration: 3 * 1000 })
            this.isSend = true
          })
        } else {
          this.$toast({ message: '请输入手机号', position: 'top', duration: 3 * 1000 })
        }
      })
    },
    // 倒计时结束触发
    finish() { this.isSend = false },
    onMobileSubmit() {
      modifyMobile(this.changeInfo).then(res => {
        this.$toast({ message: '修改成功！', duration: 3 * 1000 })
        this.getInfo()
        this.isMobile = false
        this.mobileDialog = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile_tips {
  font-size: 0.27rem;
  color: #c2c2c2;
  margin-top: 0.1rem;
}
.van-dialog {
  background: none;
}
.mobileDialog_box {
  width: 100%;
  height: 6.4rem;
  border-radius: 0.27rem;
  background: linear-gradient(180deg, #d4e9fa 0%, #fff 100%);
}
</style>
<style>
.mobileDialog_img .van-image__img {
  margin-top: 1.4rem !important;
}
.mobileDialog_title {
  font-weight: 700;
  font-size: 0.48rem;
  color: #333;
}
.mobileDialog_tips {
  font-size: 0.37rem;
  color: #999;
  margin-top: 0.11rem;
}
.mobileDialog_btn {
  width: 100%;
  margin-top: 0.91rem;
}
</style>
